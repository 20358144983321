import { Edge, StrapiStringField } from "./StrapiTypes";

export interface SimpleStrapiArticle {
	id: string;
	readtime: number;
	path: string;
	article_path: string;
	title: string;
	titleImage: string;
	categories: StrapiStringField[];
	description: string;
}

export interface StrapiArticle extends SimpleStrapiArticle {
	content: string;
	publicationDate: string;
	author: ArticleAuthor;
	tags: StrapiStringField[];
	description: string;
}

export interface AllStrapiArticles {
	edges: Edge<SimpleStrapiArticle>[];
}

export interface StrapiCategory {
	name: string;
	id: string;
	strapiId?: number;
}

export interface AllStrapiCategories {
	edges: Edge<StrapiCategory>[];
}

export interface MarkdownRemark {
	html: string;
	id: string;
}

interface ArticleAuthor {
	id: number;
	email: string;
	fullname: string;
	function: string;
	picture: string;
}

export enum ChallengeType {
	companies = "companies",
	internal = "internal",
	school = "school",
	tenant = "tenant",
	city = "city",
}

export enum ContactSubjectType {
	challenge = "Mettre en place un Challenge",
	coaching = "Mettre en place le Coaching",
	ao = "Répondre à un appel d'offres",
	partnership = "Nouer un partenariat",
	other = "Autre",
}

export enum PageFeaturing {
	companies = "companies",
	internal = "internal",
	school = "school",
	tenant = "tenant",
	homepage = "homepage",
}

export interface StrapiBlogVerbatim {
	content: string;
	articleUrl: string;
	featuredOn: PageFeaturing;
	clientLogo: string;
	authorAvatar: string;
	authorPosition: string;
	authorName: string;
}

export interface AllStrapiBlogVerbatims {
	edges: Edge<StrapiBlogVerbatim>[];
}

export interface StrapiCompaniesChallenge {
	title: string;
	description: string;
	reason?: string;
	startDate: string;
	endDate: string;
	mockupUrl?: string;
	presentationUrl?: string;
	webinarUrl?: string;
}

export interface AllStrapiCompaniesChallenge {
	edges: Edge<StrapiCompaniesChallenge>[];
}
